/*  News Css */
.main-header {
	/*position: absolute;
	top: 0;
	left: 0;*/
	width: 100%;
	z-index: 40;
}

.header-shape1 {
	width: 100%;
	max-width: 1680px;
	height: 124px;
	background-color: #eb0029;
	position: absolute;
	top: 0;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	-webkit-clip-path: path("M6.93299 11.866C18.108 34.16 35.985 57.951 52.323 72.275C85.979 101.781 123.106 118.156 167.211 122.945C173.923 123.674 382.207 123.99 841.711 123.969C1547.75 123.936 1515.41 124.142 1537 119.542C1574.47 111.561 1609.87 92.266 1637 65.038C1653.26 48.714 1664.48 32.965 1675.51 10.978L1681.02 0H841.001H0.984985L6.93299 11.866Z");
	clip-path: path("M6.93299 11.866C18.108 34.16 35.985 57.951 52.323 72.275C85.979 101.781 123.106 118.156 167.211 122.945C173.923 123.674 382.207 123.99 841.711 123.969C1547.75 123.936 1515.41 124.142 1537 119.542C1574.47 111.561 1609.87 92.266 1637 65.038C1653.26 48.714 1664.48 32.965 1675.51 10.978L1681.02 0H841.001H0.984985L6.93299 11.866Z");
	z-index: -1;
    -webkit-clip-path: none;
	clip-path: none;
	border-radius: 0 0 130% 130% / 0 0 999px 999px;
}
.text-white p {
    color: var(--text-color-1);
    margin-bottom: 0px;
}

.bg-news-color {
    background-color: var(--bg-news-color);
    padding: 5px 0px;
}


.blink-text {
    color: var(--bg-news-color);
    font-weight: var(--font-weight);
    font-size: var(--font-size-normal-18px);
    animation: var(--animation);
}

.marquee strong {
    color: var(--bg-news-color);
    font-weight: var(--font-weight);
    font-size: var(--font-size-normal-18px);
    animation: var(--animation);
}

.BlinkingText {
    background-color: #ff9800;
    padding: 15px 0px;
    margin: 10px auto;
    border: 4px #ed1b24 solid;

}

.BlinkingText h5 {
    color: var(--bg-news-color);
    font-weight: var(--font-weight);
    font-size: var(--font-size-normal-24px);
    animation: var(--animation);
}

.BlinkingText h6 {
    color: var(--bg-news-color);
    font-weight: var(--font-weight);
    font-size: var(--font-size-normal-22px);
    animation: var(--animation);
}

@keyframes blinkingText {
    0% {
        color: #000;
        transition: var(--transition);
    }

    25% {
        color: #148f00;
        transition: var(--transition);
    }

    50% {
        color: var(--bg-blinking-color-3);
        transition: var(--transition);
    }

    75% {
        color: var(--bg-blinking-color-4);
        transition: var(--transition);
    }

    100% {
        color: var(--bg-blinking-color-5);
        transition: var(--transition);
    }
}

/* top nav  css */

.top-nav {  
    padding: 15px 0;
    color:#fff;
}

.top-nav ul {
    margin: 0px;
}

.top-nav ul li {
    margin: 0px 5px;
    float: left;
}

.top-nav span {
    margin: 0px 5px 0px 0px;
}

.top-nav ul li a {
   
    color: var(--text-color-1);   
    font-weight:900;
}
.top-nav ul li {  
    color: var(--text-color-1);
    margin-right: 5px;
    font-weight:900;
}

.top-nav .fas {   
    height: 30px;
	width: 30px;
	line-height: 31px;
	background-color: rgba(255,255,255,0.3);
	color: var(--body-color);
	text-align: center;
	margin-right: 6px;
	font-size: 13px;
	border-radius: 99px;
}

.top-nav .fab {   
    height: 30px;
	width: 30px;
	line-height: 31px;
	background-color: rgba(255,255,255,0.3);
	color: var(--body-color);
	text-align: center;
	margin-right: 6px;
	font-size: 13px;
	border-radius: 99px;
}


.text-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

/* Responsive.css */

@media only screen and (max-width: 480px) {
    .top-nav ul li a {
        margin-right: 0px;
    }

    .top-nav span {
        margin-right: 0px;
    }


}