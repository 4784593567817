.bg-image-4 {
    background-image: url(../images/subscribe_bg_1.jpg);
    background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
  
       
  }


  .subscribe{
	position: relative;
	z-index: 3;
	border-radius: 20px;
	text-align: center;	
    padding:120px 15px;
    margin:0px 0px 120px 0px;
}
.subscribe h2{ font-size:40px; font-weight:600; margin-bottom:20px}
.subscribe p{ font-size:20px; font-weight:500; margin-bottom:20px}

.subscribe .form-control {
	border: 1px solid transparent;
	background-color: #fff;
	border: 1px solid #fff;
	color: #fff;
}


.subscribe .form-control, input {
	height: 55px;
	padding: 0 25px 0 25px;
	padding-right: 45px;
	border: 1px solid #e0e0e0;
	color: var(--body-color);
	background-color: #fff;
	border-radius: 28px;
	font-size: 16px;
	-webkit-transition: 0.4s ease-in-out;
	transition: 0.4s ease-in-out;
}

.subscribe-form span{ margin-left:5px;}
