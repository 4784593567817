.footer {
    width: 100%;
	overflow: hidden;
	position: relative;
	background-position: bottom right;
	background-repeat: no-repeat;
	background-color: #faf7f2;
    background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
   

}

.bg-image-3 {
    background-image: url(../images/background_pattern.png);
       
  }

.footer h4 {
    margin: 0px 0px 8px 0px;
}

.h4-line{	
	height: 3px;
	width: 100px;
	background-image: -webkit-linear-gradient(left, #eb0029, transparent);
	background-image: linear-gradient(to right, #eb0029, transparent);
    margin: 0px 0px 25px 0px;
   
}


.footer ul li {
    line-height: 28px;
    margin:8px 0px;
}

.footer-2{ padding:50px 0px 50px 0px;}

.footer-social h5 {
    color: #fff;
}


.footer-bottom-area {
    position: relative;
    display: block;
    margin: 0px 0px 0px 0px;
    padding: 25px 0px;
    background-color: #010f1c;
    z-index: 1;
   
}


.footer-bottom-area p {
    margin: 0px;
    padding: 0px;
    color:#fff;
}

.footer span {
    margin: 0px 8px 0px 0px;
}

.footer-social ul {
    margin: 0px 0px;
}

.footer-social ul li {
    float: left;
    margin: 0px 7px 0px 0px;
}

.footer-social ul li a{
   color:#4d5765;
   border:1px solid #4d5765;  
}
.footer-social span {
    margin: 0px 0px 0px 0px;
}
.footer-social a {
	display: inline-block;
	width:  40px;
	height:  40px;
	line-height: 40px;
	background-color: transparent;
	color: #eb0029;
	font-size: 14px;
	text-align: center;
	margin-right: 5px;
	border-radius: 50%;
}


.footer .icon {
	width: 30px;
	height: 30px;
	min-width: 30px;
	line-height: 32px;
	font-size: 20px;
	background-color: #eb0029;
	color: #fff;
	border-radius: 999px;
	text-align: center;
	margin-right: 10px;
    float:left;
}
.footer .icon span{ position:relative; left:4px;}

.footer .inner{ margin:10px 0px 0px 0px; width:100%; float:left;}
.footer .inner .text{ font-weight:bold;}
.footer .inner .text a{ font-weight:bold; }
.footer .inner .text span{ font-weight:bold; }
.footer .inner .text p{ font-weight:bold; }

.footer-privacy ul{ margin:0px; padding:0px;}
.footer-privacy ul li{ display:inline; }
.footer-privacy ul li a{list-style:square !important; margin-right:5px;}
.footer-privacy span{ font-size:12px;}
@media (max-width: 374.98px) {

  
}

@media (min-width: 375.98px) and (max-width: 575.98px) {

   
}


@media (min-width: 576px) and (max-width: 767.98px) {

 
}


@media (min-width: 768px) and (max-width: 991.98px) {

}


@media (min-width: 992px) and (max-width: 1199.98px) {
  
}


@media (min-width: 1200px) {}