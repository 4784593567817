/* //// Home Video Gallery css ///// */


.HomeVideo {
    padding: 4.5rem 0 5rem;
    background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	overflow: hidden;
	z-index: 2;
}

.HomeVideo h1{ margin:125px 0px 25px 0px}
.HomeVideo p{ margin:0px 0px 225px 0px}

.bg-image {
    background-image: url(../images/section-bg1.png);
   
  }

  .bg-image-1 {
    background-image: url(../images/shape8.png);
   
  }
  .top-inherit {
	top: inherit !important;
}
.section-shape.section-shape1 {
	transform: rotate(180deg);
}
.section-shape {
    position: absolute;
    top: -96px;
    left: 0;
    width: 100%;
    height: 96px;
    background-size: contain;
    background-repeat: repeat-x;
    z-index: 1;
}

.HomeVideo h2 {
    line-height: 52px;
}


.HomeVideo img {
    margin: 30px auto;

}

.HomeVideo iframe {
    margin: 30px auto;

}

.HomeVideo .btn_one {
    color: var(--text-color-1);
    margin: 10px 0px;
}

/* Responsive.css */

@media (max-width: 374.98px) {


    .HomeVideo-box-1 {
        margin-left: 10px !important;
    }


}

@media (min-width: 375.98px) and (max-width: 575.98px) {


    .HomeVideo-box-1 {
        margin-left: 10px !important;
    }


}


@media (min-width: 576px) and (max-width: 767.98px) {


    .HomeVideo-box-1 {
        margin-left: 10px !important;
    }


}


@media (min-width: 768px) and (max-width: 991.98px) {


    .HomeVideo-box-1 {
        margin-left: 10px !important;
    }


}


@media (min-width: 992px) and (max-width: 1199.98px) {}


@media (min-width: 1200px) {}