.about_us{ 
	padding: 30px 0px 90px 0px;
   }


.img-box2 {
	position: relative;
	z-index: 2;
}
.img-box2 .img1 {
	text-align: right;
}
.img-box2 .shape1 {
	position: absolute;
	
    bottom:-15px;
	left: -135px;
	z-index: -1;
}
.sub-title {
	display: inline-block;
	font-size: 20px;
	font-weight: 600;
	color: #eb0029;
	font-family: var(--font-family-3);
	text-transform: capitalize;
	margin-bottom: 20px;
	padding-top: 5px;
	position: relative;
}

.sub-title-1 {
	display: inline-block;
	font-size: 40px;
	font-weight: 600;
	color: #eb0029;
	font-family: var(--font-family-3);
	text-transform: capitalize;
	margin-bottom: 20px;
	padding-top: 5px;
	position: relative;
}


.sec-title {
	margin-bottom: 15px;
    font-family: var(--font-family-2);
    font-weight: 600;
    font-size: 24px;
}

/* Responsive.css */

@media (max-width: 374.98px) {}

@media (min-width: 375.98px) and (max-width: 575.98px) {}


@media (min-width: 576px) and (max-width: 767.98px) {}


@media (min-width: 768px) and (max-width: 991.98px) {}


@media (min-width: 992px) and (max-width: 1199.98px) {}


@media (min-width: 1200px) and (max-width: 1399.98px) {}