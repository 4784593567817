@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;1,200&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster+Two:ital,wght@0,400;0,700;1,400;1,700&display=swap');

body {
    background-color: #fff;
    font-family: var(--font-family-1);
    text-align: justify;
    margin: 0px;
    padding: 0px;
    font-size: 100%;
    line-height: 28px;
    overflow-x: hidden;

}

h1{
    font-family: var(--font-family-1);
    margin: 0px 0px 10px 0px;
    padding: 0px;
    line-height: 32px;
    font-weight: 600;
   
}
h2{
    font-family: var(--font-family-1);
    margin: 0px 0px 10px 0px;
    padding: 0px;
    line-height: 32px;
    font-weight: 600;
   
}
h3{
    font-family: var(--font-family-1);
    margin: 0px 0px 10px 0px;
    padding: 0px;
    line-height: 32px;
    font-weight: 600;
   
}
h4{
    font-family: var(--font-family-1);
    margin: 0px 0px 10px 0px;
    padding: 0px;
    line-height: 32px;
    font-weight: 600;
  
}
h5{
    font-family: var(--font-family-1);
    margin: 0px 0px 10px 0px;
    padding: 0px;
    line-height: 32px;
    font-weight: 600;
    font-size:24px;
}
h6{
    font-family: var(--font-family-1);
    margin: 0px 0px 10px 0px;
    padding: 0px;
    line-height: 32px;
    font-weight: 600;
    font-size:20px;
}

ul {
    margin: 0px;
    padding: 0px;
}

ul li {
    list-style: none;
}


p {
    font-size: var(--font-size-normal-18px);
    font-weight: var(--font-weight-400);
    line-height: 28px;
    color: var(--text-color-2);
    margin-bottom: 10px;
}

a {
    text-decoration: none;
    font-style: normal;
    color: var(--text-color-2);

}

.text-yellow {
    color: var(--bg-blinking-color-10);
}

.text-light-white {
    color: var(--bg-blinking-color-9);
}

.font-14 {
    font-size: 14px;
    color: var(--bg-blinking-color-3);
    line-height: 8px;
}

.font-14 .fa {
    margin-right: 7px;
}

.font-12 {
    font-size: 12px;
}

.font-12 .fa {
    margin-right: 7px;
}