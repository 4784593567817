
.HomeOther {
    padding: 80px 0px 80px 0px;
    background-color: var(--bg-blinking-color-14);
  
}

.HomeOther h2 {
    line-height: 52px;
}

.color-overlay {
	top: inherit;
	bottom: 0;
	background: linear-gradient(to bottom, transparent, #000000);
	height: 50%;
	opacity: .6;
}

.overlay, .dot-overlay, .color-overlay, .white-overlay, .left-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000;
	transition: all ease-in-out .5s;
	opacity: .5;
}
.color-overlay,  .color-overlay, .color-overlay {
	height: 95%;
	transition: all ease-in-out .5s;
}
.HomeOther img {
   /* margin: 30px 0px;*/

}

.padding-lr p {
       margin-top: 35px;
}

.HomeOther-box-1 {
   
    position: relative;  
    margin-top: 30px;
    transition: all 0.3s linear;
}
.HomeOther-inner-box-text{ 
	position: absolute;
	z-index: 1;
	bottom: 3px;
    padding:0px 15px;
}
.HomeOther-box-1 .HomeOther-inner-box {
    background-color: var(--bg-blinking-color-2);
    box-shadow: -2px 0px 15px 0px rgba(0, 0, 0, 0.06);
   
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 14px;
    border-radius: 100%;
    transition: all 0.3s linear;
}

.HomeOther-inner-box-text h5 {
    font-size: var(--font-size-normal-20px);
    font-weight: 700;
    color: var(--text-color-1);
    margin: 10px 0px 0px 0px;
    transition: none;
    transition: all 0.3s linear;
}

.HomeOther-inner-box-text p {
    margin: 0px 0px 25px 0px;
    font-size: var(--font-size-normal-14px);
    color: var(--text-color-1);
    line-height: 24px;
    transition: none;
}

/*
.HomeOther-box-1:hover .HomeOther-inner-box img {
    transform: rotate(360deg);
}

.HomeOther-box-1:hover h5,
.HomeOther-inner-box:hover p {
    color: var(--text-color-1);
}

.HomeOther-box-1:hover {
    background-image: -moz-linear-gradient(0deg, rgb(244, 170, 54) 0%, rgb(243, 115, 53) 100%);
    background-image: -webkit-linear-gradient(0deg, rgb(244, 170, 54) 0%, rgb(243, 115, 53) 100%);
    /* background-image: -ms-linear-gradient(0deg, rgb(244, 170, 54) 0%, rgb(243, 115, 53) 100%);*/
   /* border-color: rgb(231, 231, 231);
}
*/

.HomeOther .btn_one {
    color: var(--text-color-1);
    margin: 10px 0px;
}


/* Responsive.css */

@media (max-width: 374.98px) {

    .HomeOther-box-1 {
        margin-left: 10px !important;
    }

    .HomeOther {

        background-color: var(--bg-blinking-color-14);
        position: unset;

    }

}

@media (min-width: 375.98px) and (max-width: 575.98px) {

    .HomeOther-box-1 {
        margin-left: 10px !important;
    }

    .HomeOther {

        background-color: var(--bg-blinking-color-14);
        position: unset;

    }


}


@media (min-width: 576px) and (max-width: 767.98px) {

    .HomeOther-box-1 {
        margin-left: 10px !important;
    }

    .HomeOther {

        background-color: var(--bg-blinking-color-14);
        position: unset;

    }


}


@media (min-width: 768px) and (max-width: 991.98px) {

    .HomeOther-box-1 {
        margin-left: 10px !important;
    }




}


@media (min-width: 992px) and (max-width: 1199.98px) {}


@media (min-width: 1200px) {}