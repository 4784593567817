.products{ padding:100px 0px;}
.read{ display:none;}
.similarHeading{ margin:0px 0px 50px 0px;}

.product_style1 {
	padding: 27px 0 45px 0;
	background-color: #f2f2f2;
	box-shadow: 0px 6px 30px rgba(1,15,28,0.08);
	border-radius: 999px;
	margin-bottom:30px;
}
.product_style1 .product-img {
	margin-bottom: 0;
}
.as-product .product-img {
	position: relative;
	margin: 0 0 0px 0;
	text-align: center;
	z-index: 3;
	-webkit-transition: 0.4s ease-in-out;
	transition: 0.4s ease-in-out;
}
.as-product .product-img img {
	-webkit-transition: 0.4s ease-in-out;
	transition: 0.4s ease-in-out;
    width:250px;
    height:250px;
    margin:0px auto 25px auto;
	border-radius:100%;
}
.product_style1 .product-content {
	background-color: transparent;
	padding: 0;
	box-shadow: none;
}
.as-product .category {
	display: block;
	color: #4d5765;
	margin: -0.5em 0 5px 0;
}

.as-product .product-title {
	font-size: 24px;
	margin: 6px 0 9px 0;
	font-weight: 700;
   
}
.as-product .product-title a{ color:#000;}
.as-product .price {
	display: block;
	color: #eb0029;
	font-weight: 800;
	margin-bottom: -0.45em;
    font-size:22px;
}
.as-product .price del {
	margin-left: 10px;
	color: #4d5765;
}
.as-product .actions {
	--btn-size: 46px;
	text-align: center;
	margin-top: 30px;
}

.as-product .actions .icon-btn {
	box-shadow: 0px 4px 20px rgba(0,0,0,0.05);
	background-color: #eb0029;
	color: #fff;
}
.as-product .icon-btn:nth-child(1), .as-product .icon-btn:nth-child(3) {
	background-color: #010f1c !important;
	-webkit-transform: translateY(30px);
	-ms-transform: translateY(30px);
	transform: translateY(30px);
	visibility: hidden;
	opacity: 0;
	-webkit-transition: 0.4s ease-in-out;
	transition: 0.4s ease-in-out;
}
.as-product .actions .icon-btn {
	box-shadow: 0px 4px 20px rgba(0,0,0,0.05);
	background-color: #eb0029;   
    margin:0px 2px;
    display: inline-block;
	width:40px;
	height: 40px;
	line-height: 40px;
	font-size: 16px;
	color: #fff;
	text-align: center;
	border-radius: 50%;
	border: none;
	transition: 0.4s ease-in-out;
}


.as-product .icon-btn:nth-child(1):hover, .as-product .icon-btn:nth-child(3):hover {
	background-color: #eb0029 !important;
}

.as-product .actions .icon-btn:hover {
	background-color: #010f1c;
}
.as-product:hover .icon-btn {
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
	visibility: visible;
	opacity: 1;
}

.product-about {
	padding-left: 36px;
}
.product_meta > span {
	display: block;
	margin-bottom: 5px;
	color: #010f1c;
	font-weight: bold;
}
 .price {	
	font-size: 30px;
	font-weight: 900;
	color: #eb0029;
	margin-bottom: 20px;
	display:inline-block;
}
.price del {
	color: #4d5765;
	font-weight: 900;
	margin-left: 15px;
}


 .product-about .actions {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	gap: 5px 4px;
	margin: 10px 0 10px 0;
}

.product-about h1 small{ color:#010f1c}
.product-about .red{ color:#eb0029}
.product-about .green{ color:#29900a}

.as-product:hover{border-bottom:4px solid #eb0029}