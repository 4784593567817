.product-images-slider {
	background-color: #faf7f2;
	text-align: center;	
	padding: 80px 33px 33px 33px;
	border-radius: 20px;
    margin:0px 0px 10px 0px;
}
 .product-images-slider  img {
            margin: 0px auto;
            padding:80px 0px;
        }


.product-images-slider .swiper-button-prev {
       
        color: #000;
    }

.product-images-slider .swiper-button-next {
        
        color: #000;
    }

 .product-images-slider-thumbs  .swiper-slide {
        cursor: pointer;    
        background-color: #faf7f2;
	border-radius: 10px;
	padding: 18px 13px 13px 13px;
	cursor: pointer;    
       
 }
 .product-images-slider-thumbs{
	
}
.product-images-slider-thumbs  .active {
           
            border:2px #eb0029 solid;
            border-radius:10px;
 }


 .product-images-slider-thumbs  img {
            margin: 0px auto;
            padding:15px;
 }
   
