.social1 {
    position: fixed;
    top: 70%;
    left: 0%;
    z-index: 999
}

.social1 ul {
    margin: 0px;
    padding: 0px;
}

.social1 ul li {
    list-style-type: none;
    padding: 5px 5px !important;
    margin: 5px 0px !important;
    transition: 0.3s;
}

.social1 ul li {
    color: var(--text-color-1);
}



.register {
    position: fixed;
    left: 0px;
    bottom: 2%;
    z-index: 999;
    width: 130px;
}

.app {
    position: fixed;
    right: -2px;
    bottom: 40%;
    z-index: 999;
}

.app ul {
    margin: 0px;
    padding: 0px;
}

.app ul li {
    list-style-type: none;
    padding: 5px 5px !important;
    margin: 5px 0px !important;
    transition: 0.3s;
}

.partner {
    position: fixed;
    left: -2px;
    bottom: 40%;
    z-index: 999;
}

.partner ul {
    margin: 0px;
    padding: 0px;
}

.partner ul li {
    list-style-type: none;
    padding: 5px 5px !important;
    margin: 5px 0px !important;
    transition: 0.3s;
}

/* Responsive.css */