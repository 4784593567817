.search .icon-btn {
	display: inline-block;
	width: 56px;
	height: 56px;
	line-height: 56px;
	font-size: 16px;
	background-color: #fff;
	color: #010f1c;
	text-align: center;
	border-radius: 50%;
	border: none;
	-webkit-transition: 0.4s ease-in-out;
	transition: 0.4s ease-in-out;
	border: 1px solid #d0dbe9;
	position:relative;
	margin:0px 3px;
}
.search .icon-btn:hover {
    background-color: #eb0029;
	color:#fff;
}
.search span{ margin:0px;}
.badge {
	position: absolute;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	display: inline-block;
	text-align: center;
	background-color: #eb0029;
	color: #fff;
	padding: 0.2em 0.4em;
	border-radius: 50%;
	top: 10px;
	right: 10px;
	font-weight: 900;
	font-size: 0.7em;

}

.popup-search-box form {
	position: absolute;
	top: 50%;
	left: 50%;
	display: inline-block;
	padding-bottom: 40px;
	cursor: auto;
	width: 100%;
	max-width: 700px;
	-webkit-transform: translate(-50%, -50%) scale(0);
	-ms-transform: translate(-50%, -50%) scale(0);
	transform: translate(-50%, -50%) scale(0);
	-webkit-transition: -webkit-transform ease 0.4s;
	transition: -webkit-transform ease 0.4s;
	transition: transform ease 0.4s;
	transition: transform ease 0.4s, -webkit-transform ease 0.4s;
}

.popup-search-box.show form {
	-webkit-transition-delay: 0.5s;
	transition-delay: 0.5s;
	-webkit-transform: translate(-50%, -50%) scale(1);
	-ms-transform: translate(-50%, -50%) scale(1);
	transform: translate(-50%, -50%) scale(1);
}

.popup-search-box form input {
	font-size: 18px;
	height: 70px;
	width: 100%;
	border: 2px solid #eb0029;
	background-color: transparent;
	padding-left: 30px;
	color: #fff;
	border-radius: 50px;
}

.popup-search-box form button {
	position:relative;
	top: 0px;
	background-color: transparent;
	border: none;
	color: #fff;
	font-size: 24px;
	right: 12px;
	color: #fff;
	cursor: pointer;
	width: 70px;
	height: 70px;
	-webkit-transition: all ease 0.4s;
	transition: all ease 0.4s;
	-webkit-transform: scale(1.001);
	-ms-transform: scale(1.001);
	transform: scale(1.001);
}
