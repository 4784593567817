.HomeMenu{ padding:90px 0px; }
.title-border{	border-bottom: 1px dashed #bdbdbd;}
.category-card {
	position: relative;
	margin-left: 53px;
	margin-top: 26px;
}
.category-menu{ padding:45px 0px;}
.category-card_img {
	position: absolute;
	top: -26px;
	left: -53px;
	z-index: 2;
}
.category-card_img img{ width:100px; height:100px; border-radius:100%;}
.category-card_content {
	margin-bottom: -0.45em;
}
.category-card_title a:hover{ color:#eb0029}
.category-card_content {
	background-color: #fff;
	padding: 33px 26px 33px 55px;
	box-shadow: 0px 6px 30px rgba(1,15,28,0.05);
	border-radius: 20px;
	position: relative;
	overflow: hidden;
}
.category-card_title {
	font-size: 20px;
	font-weight: 600;
	margin-top: -0.23em;
	margin-bottom: 5px;
}
.category-card .icon-btn {
	--btn-size: 72px;
	/*line-height: 60px;*/
	padding-right: 15px;
	background-color:#ff9d2d;
	color: #fff;
	position: absolute;
	bottom: -17px;
	right: -20px;
}
.category-card .icon-btn:hover {
	background-color: #eb0029;
}

.icon-btn {
	display: inline-block;
	width:  56px;
	height:  56px;
	line-height:  40px;
	font-size: 16px;
	background-color: #fff;
	color: #fff;
	text-align: center;
	border-radius: 50%;
	border: none;
	-webkit-transition: 0.4s ease-in-out;
	transition: 0.4s ease-in-out;
}