.menu-bg {
    /* background-color: rgb(232 72 47 / 90%); */
    background-color: #fff;
    padding: 0 30px;
	border-radius: 999px;
	box-shadow: 0px 15px 20px rgba(1,15,28,0.1);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: all .5s;
    transition: all .5s;
    margin: 15px auto;

}



/** SiteNavigation(Menu) **/
.site-navigation {
    position: relative;
}

.site-navigation .ttm-right-header {
    float: right;
    padding-left: 35px;
    position: relative;
}

.site-navigation .ttm-header-icons::before {
    display: block;
    content: "";
    position: absolute;
    height: 30px;
    width: 1px;
    left: 10px;
    top: 50%;
    margin-top: -14px;
    background-color: rgba(38, 48, 69, 0.09);
}

.menu-dropdown,
.menu input[type="checkbox"] {
    display: none;
}

.menu label:hover {
    cursor: pointer;
}

@-webkit-keyframes grow {
    0% {
        display: none;
        opacity: 0;
    }

    50% {
        display: block;
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

@keyframes grow {
    0% {
        display: none;
        opacity: 0;
    }

    50% {
        display: block;
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

.menu {
    display: inline-block;
    /* height: 93px;*/
    display: -webkit-box;
    display: -ms-flexbox;
    /*  display: flex;*/
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-transform: capitalize;
}


li.menu-hasdropdown.active a {
    color: var(--text-color-4);
}

li.menu-hasdropdown a {
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
    color: var(--text-color-4);
    display: block;
    padding: 15px 0;
    position: relative;
    font-size: 16px;
    /*line-height: 16px;*/
    -webkit-transition: all .5s;
    transition: all .5s;
    font-weight: 600;
}

li.menu-hasdropdown a label {
    margin: 0;
   
}
.menu-hasdropdown ul li{ float:none;}

li.menu-hasdropdown ul li a {
    font-weight: 400;
    color:#000;
   
}


/* Main menu */

/* hide show mixin */
@media only screen and (min-width: 1200px) {

    .menu>ul>li {
        display: inline-block;
        color: var(--text-color-4);
    }

    .menu>ul>li>a {

        padding: 10px 15px;
        position: relative;
        display: block;
        font-size: 16px;
        font-weight: 600;
        border: none;
        color: var(--text-color-4);
    }

    .menu>ul>li>a::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background-color: var(--bg-blinking-color-7);
        height: 2px;
        -webkit-transform: scale(0, 1);
        transform: scale(0, 1);
        -webkit-transition: -webkit-transform 0.25s cubic-bezier(0.37, 0.31, 0.2, 0.85);
        transition: -webkit-transform 0.25s cubic-bezier(0.37, 0.31, 0.2, 0.85);
        transition: transform 0.25s cubic-bezier(0.37, 0.31, 0.2, 0.85);
        transition: transform 0.25s cubic-bezier(0.37, 0.31, 0.2, 0.85), -webkit-transform 0.25s cubic-bezier(0.37, 0.31, 0.2, 0.85);
    }

    .menu>ul>li>a:not([href]):hover {
        color: var(--text-color-4);
    }

    .menu>ul>li:hover a::before,
    .menu>ul>li.active>a::before {
        -webkit-transform-origin: left center;
        transform-origin: left center;
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    .menu-hasdropdown {
        position: relative;
    }

    .menu-hasdropdown:hover>ul {
        display: block;
        -webkit-animation: grow .5s ease-in-out;
        animation: grow .5s ease-in-out;
    }

    .menu-hasflyout>a::after {
        content: "\f105";
        position: absolute;
        background-color: transparent;
        right: 12px;
        top: 16px;
        display: inline-block;
        font-family: "Font Awesome 5 Pro";
        font-size: 15px;
        margin-left: 4px;
        opacity: 0.8;
    }


    .menu-hasdropdown>ul {
        position: absolute;
        top: 100%;
        left: 0;
        width: 250px;
        background-color: var(--bg-blinking-color-2);
        -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        -webkit-transform: translate(0, 0px);
        transform: translate(0, 0px);
        -webkit-transition: opacity 200ms ease-in, visibility 200ms ease-in, -webkit-transform 200ms ease-in;
        transition: opacity 200ms ease-in, visibility 200ms ease-in, -webkit-transform 200ms ease-in;
        transition: opacity 200ms ease-in, transform 200ms ease-in, visibility 200ms ease-in;
        transition: opacity 200ms ease-in, transform 200ms ease-in, visibility 200ms ease-in, -webkit-transform 200ms ease-in;
        z-index: 9;
    }

    .menu-hasdropdown>ul li a {
        padding: 15px;
    }

    .menu-hasdropdown>ul li a::before {
        content: "";
        margin-right: 5px;
        position: absolute;
        width: 6px !important;
        height: 2px;
        opacity: 0;
        left: 7px;
        -webkit-transition: .5s all;
        transition: .5s all;
        background-color: var(--bg-blinking-color-12);
        top: 22px;
    }

    .menu-hasdropdown>ul li a:hover::before,
    .menu-hasdropdown>ul li a.active::before {
        opacity: 1;
        left: 15px;
    }

    .menu-hasdropdown>ul li a:hover,
    .menu-hasdropdown>ul li a.active {
        padding-left: 30px;
        background-color: var(--bg-blinking-color-2);
        color: var(--text-color-4);
    }

    .menu-hasflyout>ul {
        left: 100%;
        top: 0;
    }

    .menu-hasflyout .menu-downicon {
        display: none;
    }

}