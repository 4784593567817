.product-about .share {
	margin-top: 24px;
}
.share-title i, .product-about .share-title i {
	color: #eb0029;
}
.share-title, .product-about .share-title {
	margin-bottom: 8px;
	font-weight: 500;
	color: #010f1c;
}
.as-social a, .product-about .as-social a {
	background-color: #e0e0e0;
	color: #4d5765;
	border-radius: 5px;
}
.as-social a {
	display: inline-block;
	width:  40px;
	height:  40px;
	line-height:  40px;
	background-color: #fff;
	color: #eb0029;
	font-size: 14px;
	text-align: center;
	margin-right: 5px;
	border-radius: 50%;
}
.as-social a:hover{
    background-color:#eb0029;
    color:#fff;
}