/* //// Home Services css ///// */

.HomeServices {
    padding: 90px 0px 30px 0px;
  
  
}
.bg-image-5 {
    background-image: url(../images/feature_bg_1.png);
    background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
  
       
  }
.HomeServices-box{ margin:75px 0px;}
.HomeServices-box-1 {
	text-align: center;
	padding: 0 20px;
    border-right: 1px dashed #bdbdbd;
    
}


.HomeServices-inner-box img {
	margin-bottom: 28px;
    width:125px;
    height:125px;
}

/* Responsive.css */

@media (max-width: 374.98px) {

  

}

@media (min-width: 375.98px) and (max-width: 575.98px) {

 


}


@media (min-width: 576px) and (max-width: 767.98px) {

  

}


@media (min-width: 768px) and (max-width: 991.98px) {

  

}


@media (min-width: 992px) and (max-width: 1199.98px) {}


@media (min-width: 1200px) {}