/* //// Home Gallery css ///// */

.slick-slide img{margin:0px auto}
.HomeGallery {
    padding: 80px 0px;
}

.HomeGallery p {
    margin-bottom: 30px;
}

.HomeGallery h2 {
    line-height: 52px;
}


.HomeGallery img {
    margin: 15px auto;

}

.HomeGallery-box-1 {
    position: relative;
    overflow: hidden;
}

.HomeGallery-box-1 .HomeGallery-inner-box {
    position: relative;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-property: -webkit-transform, -webkit-box-shadow;
    transition-property: -webkit-transform, -webkit-box-shadow;
    transition-property: transform, box-shadow;
    transition-property: transform, box-shadow, -webkit-transform, -webkit-box-shadow;
}

.HomeGallery-box-1 .HomeGallery-view-overlay {
    position: absolute;
    top: 0;
    opacity: 0;
    z-index: 1;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.75);
}

.HomeGallery-box-1 .HomeGallery-media-link a {
    display: inline-block;
    font-size: 17px;
    position: absolute;
    top: 42%;
    right: 50%;
    margin-top: -17px;
    width: 45px;
    height: 45px;
    line-height: 45px;
    border: 1px solid #2d4a8a;
    border-radius: 0;
    text-align: center;
    margin-right: 3px;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
    -webkit-transition: .5s;
    transition: .5s;
    background-color: #2d4a8a;
    color: #fff;
}

.HomeGallery-box-1 .HomeGallery-media-link a.media-link {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
    -webkit-transition: 1s;
    transition: 1s;
    margin-right: -51px;
}

.HomeGallery-box-1 .HomeGallery-content {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    padding: 15px;
   /* -webkit-transform: translateY(100%);
    transform: translateY(100%);*/
    z-index: 2;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    text-align: center;
    background-color: #263045;
}

.HomeGallery-title h5 {
    font-size: 19px;
    line-height: 26px;
    margin-bottom: 0px;
}


.HomeGallery-box-1:hover .HomeGallery-inner-box {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
}

.HomeGallery-title a{ color:#fff}

.HomeGallery-box-1 .HomeGallery-media-link a:hover {
    background-color: transparent;
    color: #2d4a8a;
}

.HomeGallery-box-1 .HomeGallery-media-link a.media-link {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
    -webkit-transition: 1s;
    transition: 1s;
    margin-right: -51px;
}

.HomeGallery-box-1:hover .HomeGallery-view-overlay {
    opacity: 1;
}

.HomeGallery-box-1:hover .HomeGallery-media-link a {
    -webkit-transform: translate(0);
    transform: translate(0);
}

.HomeGallery-box-1:hover .HomeGallery-content {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
}

.HomeGallery-box-1 .HomeGallery-content .HomeGallery-title h5 {
    color: #fff;
}

/* Responsive.css */

@media (max-width: 374.98px) {


    .HomeGallery-box-1 {
        margin-left: 10px !important;
    }



}

@media (min-width: 375.98px) and (max-width: 575.98px) {


    .HomeGallery-box-1 {
        margin-left: 10px !important;
    }




}


@media (min-width: 576px) and (max-width: 767.98px) {

    .HomeGallery-box-1 {
        margin-left: 10px !important;
    }



}


@media (min-width: 768px) and (max-width: 991.98px) {

    .HomeGallery-box-1 {
        margin-left: 10px !important;
    }




}


@media (min-width: 992px) and (max-width: 1199.98px) {}


@media (min-width: 1200px) {}